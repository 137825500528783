<template>
  <div>
    <van-empty description="开发中...尽情期待"></van-empty>
    <Tabbar />
  </div>
</template>

<script>
import Tabbar from '@/components/tabbar'
export default {
  name: 'msg',
  components: {
    Tabbar,
  },
}
</script>

<style lang="less" scoped></style>
